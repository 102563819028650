import IdentityManager from "@arcgis/core/identity/IdentityManager"
import arcgisConfig from "@arcgis/core/config"
import type { LayerAuthServiceTokenFragment }
  from "@/v2-console-shared/layer/auth/__types__/layer.auth.fragment"

export function registerLayerServiceTokens(
  tokens: LayerAuthServiceTokenFragment["Tokens"]
) {
  for(const t of tokens) {
    if(t.AuthType === "fixed-api-key") {
      // prevent the intercetor from being added multiple times
      const ex = arcgisConfig.request.interceptors
        .findIndex((i: any) => i.Code === t.Code)
      if(ex !== -1) {
        arcgisConfig.request.interceptors.splice(ex, 1)
      }

      arcgisConfig.request.interceptors.push({
        id: t.Code,
        urls: t.ServerUrl,
        before(params) {
          if(!params.requestOptions.query) params.requestOptions.query = {}
          params.requestOptions.query["api-key"] = t.Token
        }
      } as any)
    }
    else if(t.AuthType.includes("arcgis")) {
      IdentityManager.registerToken({
        token: t.Token,
        expires: t.ExpiresAt,
        server: t.ServerUrl,
        ssl: t.IsSSL ?? true
      })
    }
  }
}
