import { lazy } from "react"
import { lazyRetry } from "@/v2-console-shared/utils/utils.router"
import CADASTRE_VALUATION_TEXTS, { type CadastreValuationTexts }
  from "@/v2-console-ui/cadastre/valuation/cadastreValuation.text"
import type { CadastreModuleContentInputProps }
  from "@/v2-console/cadastre/cadastre.types"
import FeatureProvider from "@/v2-console/feature/FeatureProvider"
import CadastreModuleLoadingFallback
  from "@/v2-console/cadastre/module/CadastreModuleLoadingFallback"
const CadastreModuleValuationSummary = lazy(() => lazyRetry(() => import(
  "@/v2-console/cadastre/module/valuation/summary/CadastreModuleValuationSummary"
)))

type CadastreModuleValuationProps = CadastreModuleContentInputProps

function CadastreModuleValuationSummarySuspender(props: CadastreModuleValuationProps) {
  return (
    <FeatureProvider<CadastreValuationTexts>
      code="UI_CadastreValuation"
      fallbackTexts={CADASTRE_VALUATION_TEXTS}
      loadingFallback={<CadastreModuleLoadingFallback />}
      isLazyLoaded
    >
      <CadastreModuleValuationSummary {...props} />
    </FeatureProvider>
  )
}

export default CadastreModuleValuationSummarySuspender
