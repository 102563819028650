import type {
  CadastreModuleConfigs,
  CadastreModuleContent
} from "@/v2-console/cadastre/cadastre.types"
import {
  EXTRA_PANELS_AFTER_SELECTOR,
  EXTRA_PANELS_OUTSIDE_AFTER_SELECTOR
} from "@/v2-console/constants"
import CadastreModuleBuildingSuspender
  from "@/v2-console/cadastre/module/building/CadastreModuleBuildingSuspender"
import CadastreModulePropertySuspender
  from "@/v2-console/cadastre/module/property/CadastreModulePropertySuspender"
import CadastreModuleIndustrySuspender
  from "@/v2-console/cadastre/module/industry/CadastreModuleIndustrySuspender"
import CadastreModuleHierarchySuspender
  from "@/v2-console/cadastre/module/hierarchy/CadastreModuleHierarchySuspender"
import CadastreModuleListingSuspender
  from "@/v2-console/cadastre/module/listing/CadastreModuleListingSuspender"
import type { CadastreTexts } from "@/v2-console/cadastre/cadastre.text"
import CadastreModuleValuationSummarySuspender from "@/v2-console/cadastre/module/valuation/summary/CadastreModuleValuationSummarySuspender"
import CadastreModuleValuationEstimationSuspender from "@/v2-console/cadastre/module/valuation/estimation/CadastreModuleValuationEstimationSuspender"
// import CadasterModuleIndexValuationSespender from "@/v2-console/cadastre/module/valuation/indexValuation/CadasterModuleIndexValuationSespender"
// import CadasterModuleComparableSalesSuspender from "@/v2-console/cadastre/module/valuation/comparableSales/CadasterModuleComparableSalesSuspender"

export const CADASTRE_PANEL_MAX_WIDTH = Number.POSITIVE_INFINITY

export const SIDE_TO_PORTAL_SELECTOR = {
  left: EXTRA_PANELS_AFTER_SELECTOR,
  right: EXTRA_PANELS_OUTSIDE_AFTER_SELECTOR
}

export const SIDE_TO_RESIZABLE_POSITION = {
  left: "end",
  right: "start"
} as const

/**
 * Mapping of available cadastre module ids
 */
const CADASTRE_MODULE_IDS = {
  property: "property",
  building: "building",
  industry: "industry",
  hierarchy: "hierarchy",
  listing: "listing",
  valuation_summery: "valuation_summery",
  valuation_estimation: "valuation_estimation",
  index_valuation: "index_valuation",
  comparable_sales: "comparable_sales"
}

/**
 * Mapping of available cadastre module content by Id
 * @todo: lazy load in these when we see fit. Needs to have own feature guards?
 */
export const CADASTRE_MODULE_CONTENT: Record<string, CadastreModuleContent> = {
  [CADASTRE_MODULE_IDS.property]: CadastreModulePropertySuspender,
  [CADASTRE_MODULE_IDS.building]: CadastreModuleBuildingSuspender,
  [CADASTRE_MODULE_IDS.industry]: CadastreModuleIndustrySuspender,
  [CADASTRE_MODULE_IDS.hierarchy]: CadastreModuleHierarchySuspender,
  [CADASTRE_MODULE_IDS.listing]: CadastreModuleListingSuspender,
  [CADASTRE_MODULE_IDS.valuation_summery]: CadastreModuleValuationSummarySuspender,
  [CADASTRE_MODULE_IDS.valuation_estimation]: CadastreModuleValuationEstimationSuspender
  // [CADASTRE_MODULE_IDS.index_valuation]: CadasterModuleIndexValuationSespender,
  // [CADASTRE_MODULE_IDS.comparable_sales]: CadasterModuleComparableSalesSuspender
}

/**
 * Array of Cadastre modules to show
 * @note: this state will in the future be moved serverSide and will
 * be used to determine which modules to show, and how
 */
export const DEFAULT_CADASTRE_MODULES_CONFIGS: CadastreModuleConfigs[] = [
  {
    id: CADASTRE_MODULE_IDS.hierarchy,
    onlyInNorway: true
  },
  {
    id: CADASTRE_MODULE_IDS.property
  },
  {
    id: CADASTRE_MODULE_IDS.building
  },
  {
    id: CADASTRE_MODULE_IDS.industry
  }
]

export const LISTING_CADASTRE_MODULES_CONFIGS: CadastreModuleConfigs[] = [
  {
    id: CADASTRE_MODULE_IDS.listing,
    onlyInNorway: true
  }
]
export const VALUATION_CADASTRE_MODULES_CONFIGS: CadastreModuleConfigs[] = [
  {
    id: CADASTRE_MODULE_IDS.valuation_summery,
    onlyInNorway: true,
    isFullWidth: true
  },
  {
    id: CADASTRE_MODULE_IDS.valuation_estimation,
    onlyInNorway: true,
    isFullWidth: true
  }
  // {
  //   id: CADASTRE_MODULE_IDS.index_valuation,
  //   onlyInNorway: true,
  //   isFullWidth: true
  // },
  // {
  //   id: CADASTRE_MODULE_IDS.comparable_sales,
  //   onlyInNorway: true,
  //   isFullWidth: true
  // }
]

type CadastreTab = {
  label: keyof CadastreTexts,
  value: string,
  cadastreModuleConfigs: CadastreModuleConfigs[]
}

export const CADASTRE_TABS: Record<string, CadastreTab> = {
  default: {
    label: "UI_Cadastre_TabGeneral",
    value: "General",
    cadastreModuleConfigs: DEFAULT_CADASTRE_MODULES_CONFIGS
  },
  listing: {
    label: "UI_Cadastre_TabListing",
    value: "Listing",
    cadastreModuleConfigs: LISTING_CADASTRE_MODULES_CONFIGS
  },
  valuation: {
    label: "UI_Cadastre_TabValuation",
    value: "Valuation",
    cadastreModuleConfigs: VALUATION_CADASTRE_MODULES_CONFIGS
  }
}
