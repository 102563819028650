import { useText } from "@/v2-ui/text/text.context"
import { type RootTexts } from "@/v2-console-shared/texts"

const CADASTRE_VALUATION_TEXTS = {
  "UI_CadastreValuation_Comparable_Sales_Header": {
    "Name": "Comparable sales",
    "Description": "Comparable sales are an indicator of what homes in the same area have been sold for. This is an important factor in understanding what is the right price for the object."
  },
  "UI_CadastreValuation_Index_Valuation_Header": {
    "Name": "Index Valuation",
    "Description": "Indexed valuation is produced by adjusting the previous transaction for the price development since the transaction took place. In some cases, there will not be an estimate here even if there has been a previous transaction. The reason for this will be that the previous transaction is not considered a representative transaction in the free market. An example of non-representative transaction types is transactions that take place within a family."
  },
  "UI_CadastreValuation_Sales_Header": {
    "Name": "Placepoint Sale"
  },
  "UI_CadastreValuation_Estimated": {
    "Name": "Placepoint-estimated",
    "Description": "The estimation is generated by a machine learning model that is trained on data from advertisements, sales, and the property registry to predict housing prices. The model is an ensemble model, which means that the estimate is an aggregation of a number of machine learning models."
  },
  "UI_CadastreValuation_Estimation_Selling": {
    "Name": "Estimated Selling Price",
    "Description": "Estimated Selling Price"
  },
  "UI_CadastreValuation_Estimation_Accuracy": {
    "Name": "Accuracy",
    "Description": "Estimation Accuracy."
  },
  "UI_CadastreValuation_Estimation_Price_Range": {
    "Name": "Estimated Price Range",
    "Description": "Estimated Price Range"
  },
  "UI_CadastreValuation_Estimation_Low_Range": {
    "Name": "Low",
    "Description": "Low"
  },
  "UI_CadastreValuation_Estimation_Medium_Range": {
    "Name": "Medium",
    "Description": "Medium"
  },
  "UI_CadastreValuation_Estimation_High_Range": {
    "Name": "High",
    "Description": "High"
  },
  "UI_CadastreValuation_Estimation_Min": {
    "Name": "Minimum",
    "Description": "Lowest estimated sale value."
  },
  "UI_CadastreValuation_Estimation_Max": {
    "Name": "Maximum",
    "Description": "Highest estimated sale value."
  }
}

export type CadastreValuationTexts = typeof CADASTRE_VALUATION_TEXTS & RootTexts
export const useCadastreValuationText = useText<CadastreValuationTexts>
export default CADASTRE_VALUATION_TEXTS
