import { useMemo } from "react"
import { useToast } from "@/v2-ui/toast/base/hooks/use-toast"
import AppContextErrorNotification
  from "@/v2-console/app/context/AppContextErrorNotification"
import useAppContextQuery from "@/v2-console/app/context/app.context.query"
import { registerLayerServiceTokens }
  from "@/v2-console-shared/layer/auth/layer.auth.utils"
import { identify } from "@/v2-console-shared/analytics"
import { setUser } from "@/v2-console/sentry"
import AppContextProvider, {
  type AppContextProviderProps,
  APP_CTX_LOADED_EVENT,
  DEFAULT_APP_CTX,
  EVENTS
} from "@/v2-console/app/context/AppContextProvider"
import { updateArcGisLanguage }
  from "@/v2-console/currentUser/language/currentUser.language.utils"

export type AppContextLoaderProps = Omit<AppContextProviderProps, "value">

function AppContextLoader(props: AppContextLoaderProps) {
  const { toast } = useToast()

  const { data, refetch, startPolling } = useAppContextQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      layerTokensInput: {
        referer: window.location.origin
      }
    },
    onCompleted(d) {
      registerLayerServiceTokens(d.getServiceTokens.Tokens)
      const pi = new Date(d.getServiceTokens.ExpiresAt).getTime() - Date.now()
      startPolling(pi)

      setUser(d.getCurrentUser)
      identify(d.getCurrentUser)

      // set language for ArcGIS
      updateArcGisLanguage(d.getCurrentUser.ActiveLanguageCode)

      // @note: could add context as event payload if needed
      EVENTS.dispatchEvent(new CustomEvent(APP_CTX_LOADED_EVENT))
    },
    onError(e) {
      const t = toast({
        title: "En feil oppstod med autentiseringen",
        variant: "destructive",
        description: (
          <AppContextErrorNotification
            error={e}
            onRetry={() => {
              refetch()
              t.dismiss()
            }}
            onDismiss={() => {
              t.dismiss()
            }}
          />
        )
      })
    }
  })

  const context = useMemo(() => {
    if(!data) return DEFAULT_APP_CTX
    return {
      currentUser: data.getCurrentUser,
      events: DEFAULT_APP_CTX.events,
      basemaps: data.getBasemaps,
      navLayers: data.getNavLayers
    }
  }, [
    data
  ])

  return (
    <AppContextProvider
      value={context}
      {...props}
    />
  )
}

export default AppContextLoader
