import cn from "@/v2-ui/utils/utils.cn"

export type CardTitleCustomProps = {
  className?: string
  children?: JSX.Element
}

function CardTitleCustom(props: CardTitleCustomProps) {
  const { className, children } = props
  return (
    <div className={cn("ui-card-title", className)}>
      {children}
    </div>
  )
}

export default CardTitleCustom
