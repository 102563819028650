import { lazy } from "react"
import { lazyRetry } from "@/v2-console-shared/utils/utils.router"
import CADASTRE_VALUATION_TEXTS, { type CadastreValuationTexts }
  from "@/v2-console-ui/cadastre/valuation/cadastreValuation.text"
import CadastreModuleLoadingFallback
  from "@/v2-console/cadastre/module/CadastreModuleLoadingFallback"
import FeatureProvider from "@/v2-console/feature/FeatureProvider"
import type { CadastreModuleContentInputProps }
  from "@/v2-console/cadastre/cadastre.types"

const CadastreModuleValuationEstimation = lazy(() => lazyRetry(() => import(
  "@/v2-console/cadastre/module/valuation/estimation/CadastreModuleValuationEstimation"
)))

type CadastreModuleValuationSuspenderProps = CadastreModuleContentInputProps

function CadastreModuleValuationEstimationSuspender(props: CadastreModuleValuationSuspenderProps) {
  return (
    <FeatureProvider<CadastreValuationTexts>
      code="UI_CadastreValuation"
      fallbackTexts={CADASTRE_VALUATION_TEXTS}
      loadingFallback={<CadastreModuleLoadingFallback />}
      isLazyLoaded
    >
        <CadastreModuleValuationEstimation {...props} />
    </FeatureProvider>
  )
}
export default CadastreModuleValuationEstimationSuspender
