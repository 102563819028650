import "./card.css"
import Card from "./Card"
import CardContent from "./CardContent"
import CardHeader from "./CardHeader"
import CardFooter from "./CardFooter"
import CardDescription from "./CardDescription"
import CardTitle from "./CardTitle"
import CardTitleCustom from "./CardTitleCustom"

export type { CardProps } from "./Card"
export type { CardContentProps } from "./CardContent"
export type { CardHeaderProps } from "./CardHeader"
export type { CardFooterProps } from "./CardFooter"
export type { CardDescriptionProps } from "./CardDescription"
export type { CardTitleProps } from "./CardTitle"
export type { CardTitleCustomProps } from "./CardTitleCustom"

export default Object.assign(Card, {
  Content: CardContent,
  Header: CardHeader,
  Footer: CardFooter,
  Description: CardDescription,
  Title: CardTitle,
  CustomTitle: CardTitleCustom
})
