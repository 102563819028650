import type { FormatValue } from "@/v2-ui/utils/utils.formatting"

export const CSS_URL_DARK = "https://js.arcgis.com/4.31/@arcgis/core/assets/esri/themes/dark/main.css"
export const CSS_URL_LIGHT = "https://js.arcgis.com/4.31/@arcgis/core/assets/esri/themes/light/main.css"
export const API_KEY = "AAPKab145fadcfb746119113efbd9a34f7818VbhMpqco1OWmfgTt9IVIBX9EK9XQM04K2smu4OH6QFxNClNHPPS_YBGUcXHwTjr"
export const LOCALE = "nb"

export const DEFAULT_GOTO_ZOOM_SCALE = 1000

export const WKID = {
  db: 4326,
  ui: 25833
}

export const DEFAULT_VIEWPOINT = {
  tx: 261993,
  ty: 6649383,
  tz: 14,
  ct: 45,
  ch: 0,
  cf: 55,
  r: 0,
  s: 1000
}

export const COMMON_VIEW_CONFIG = {
  container: null,
  popup: {
    dockOptions: {
      breakpoint: false
    }
  },
  ui: {
    components: []
  },
  background: {
    color: [ 0, 0, 0, 0 ]
  },
  padding: {
    top: 0,
    left: 0,
    bottom: 0
  },
  breakpoints: {
    xlarge: 5,
    large: 4,
    medium: 3,
    small: 2,
    xsmall: 1
  },
  highlightOptions: {
    color: "#00ffff" as any,
    haloOpacity: 1,
    fillOpacity: 0.1
  },
  // These props should not be set here, as they depend on the active basemap
  // spatialReference: null,
  constraints: {}
} as __esri.MapViewProperties & __esri.SceneViewProperties

export const SCENE_VIEW_CONFIG = {
  viewingMode: "local",
  qualityProfile: "low",
  alphaCompositingEnabled: false,
  environment: {
    starsEnabled: false,
    lighting: {
      cameraTrackingEnabled: false,
      directShadowsEnabled: false
    },
    atmosphereEnabled: false,
    atmosphere: {
      quality: "low"
    }
  }
} as __esri.SceneViewProperties

export const PRINT_SERVICE_URL = "https://services.geodataonline.no/arcgis/rest/services/Georapport/Kartutskrift/GPServer/Standard"
export const GEOMETRY_SERVICE_URL = "https://services.geodataonline.no/arcgis/rest/services/Utilities/Geometry/GeometryServer"
export const SOLVE_SERVICE_AREA_URL = "https://services.geodataonline.no/arcgis/rest/services/Geosok/GeosokRute3/NAServer/Service%20Area/solveServiceArea"
export const LOCATOR_SERVICE_URL = "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer"
export const ROUTE_SERVICE_URL = "https://services.geodataonline.no/arcgis/rest/services/Geosok/GeosokRute3/NAServer/Route"

export const MAP_ID_BY_NAME = {
  "dev": "4e088b2bbdcb40bca8198dd91b1044c1",
  "next": "4e088b2bbdcb40bca8198dd91b1044c1",
  "test": "4e088b2bbdcb40bca8198dd91b1044c1",
  "prod": "196f63dad93d4059892c4c8e1711957b",
  "scene-dev": "a6fe4d9fdd724e03b05e3b992372df16",
  "scene-next": "5aa595da7ef24a7fab456ee8c05edf9e",
  "scene-test": "51fda46f0a0d4c74a2bce2d704a72ed0",
  "scene-staging": "fc644a83645b432b8e9d4e3362732bb0",
  "scene-prod": "7d4759fc688b43ff8c9da31e43c2374c"
}

export const LOT_LAYER_ID = "lots"
export const COMPANY_LAYER_ID = "companies"
export const PARKING_AREA_LAYER_ID = "31"
export const BUILDING_SCENE_LAYER_ID = "building-scene-layer"
export const BUILDING_SUB_SCENE_LAYER_ID = "building-sub-scene-layer"

export const CADASTRE_PLOTS_BUFFER_LAYER_ID = "cadastre-plots-buffer"
export const CADASTRE_PLOTS_LAYER_ID = "cadastre-plots-feature"
export const CADASTRE_PLOTS_LAYER_URL = "https://services.geodataonline.no/arcgis/rest/services/Geomap_UTM33_EUREF89/GeomapMatrikkel/MapServer/8"

export const BUILDINGS_BUFFER_LAYER_ID = "buildings-buffer"
export const BUILDINGS_LAYER_ID = "buildings-feature"
export const BUILDINGS_LAYER_URL = "https://services.geodataonline.no/arcgis/rest/services/Geomap_UTM33_EUREF89/GeomapMatrikkel/MapServer/9"

export const BUILDINGS_SCENE_LAYER_URL = "https://services.geodataonline.no/arcgis/rest/services/Geoscene/GeosceneBygning2/SceneServer"

export const ELEVATION_LAYER_TITLE = "ElevationLayer"
export const ELEVATION_LAYER_URL = "https://services.geodataonline.no/arcgis/rest/services/Geocache_UTM33_EUREF89/GeocacheTerreng/ImageServer"

export const NAV_LAYER_ID = "nav-layer"

export const WIDGET_POSITION = "top-right"

export const WIDGET_ORDER = {
  ZOOM: 0,
  COMPASS: 1
}

/**
 * @see https://pro.arcgis.com/en/pro-app/latest/help/data/geodatabases/overview/arcgis-field-data-types.htm
*/
export const FIELD_TYPES_TO_FORMAT_VALUE: Record<__esri.Field["type"], FormatValue> = {
  "small-integer": "number",
  "integer": "number",
  "single": "number",
  "double": "number",
  "long": "number",
  "string": "string",
  "date": "date",
  "oid": "string",
  "guid": "string",
  "global-id": "string",
  "geometry": null,
  "blob": null,
  "raster": null,
  "xml": null,
  "big-integer": "string",
  "date-only": "string",
  "time-only": "string",
  "timestamp-offset": "string"
}

export const SUPPORTED_ARCGIS_LANGUAGE_CODES = {
  ar: "ar",
  bg: "bg",
  bs: "bs",
  ca: "ca",
  cs: "cs",
  da: "da",
  de: "de",
  el: "el",
  en: "en",
  es: "es",
  et: "et",
  fi: "fi",
  fr: "fr",
  he: "he",
  hr: "hr",
  hu: "hu",
  id: "id",
  it: "it",
  ja: "ja",
  ko: "ko",
  lt: "lt",
  lv: "lv",
  nb: "nb",
  nl: "nl",
  pl: "pl",
  "pt-BR": "pt-BR",
  "pt-PT": "pt-PT",
  ro: "ro",
  ru: "ru",
  sk: "sk",
  sl: "sl",
  sr: "sr",
  sv: "sv",
  th: "th",
  tr: "tr",
  uk: "uk",
  vi: "vi",
  "zh-CN": "zh-CN",
  "zh-HK": "zh-HK",
  "zh-TW": "zh-TW"
}
