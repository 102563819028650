import type { ComponentProps, ReactNode } from "react"
import { TooltipButton } from "@/v2-ui/button"
import cn from "@/v2-ui/utils/utils.cn"

type BaseProps = Omit<ComponentProps<"div">, "children">

export type HeaderProps = BaseProps & {
  title: string,
  titleClassName?: string,
  description?: ReactNode,
  tooltipContent?: string | ReactNode,
  tooltipPosition?: "beforeTitle" | "afterTitle",
  renderActions?: ReactNode
  renderCloseButton?: ReactNode
}

function Header(props: HeaderProps) {
  const {
    title,
    description,
    tooltipContent,
    tooltipPosition = "afterTitle",
    renderActions,
    renderCloseButton,
    className,
    titleClassName,
    ...divProps
  } = props

  return (
    <div
      className={cn("header", className)}
      {...divProps}
    >
      <div
        className={cn("header--title", titleClassName)}
      >
        {tooltipContent && tooltipPosition === "beforeTitle"
          ? <TooltipButton
            content={tooltipContent}
            iconSrc="info"
            variant="no-background"
            size="fit"
          />
          : null
        }
        <span className="truncate">{title}</span>
        {tooltipContent && tooltipPosition === "afterTitle"
          ? <TooltipButton
            content={tooltipContent}
            iconSrc="info"
            variant="no-background"
            size="fit"
          />
          : null
        }

        {renderActions || renderCloseButton
          ? <div className="flex gap-1 ml-auto">
            {renderActions
              ? renderActions
              : null
            }
            {renderCloseButton
              ? renderCloseButton
              : null
            }
          </div>
          : null
        }
      </div>
      {description
        ? <div
          className="header--description"
        >
          {description}
        </div>
        : null
      }
    </div>
  )
}

export default Header
